import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { hourlyRate, monthlySeo, experienceYears } from "../../components/Helpers";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";
import ProfileCard from "../../components/ProfileCard";
import { useAlternateLangs } from "../../components/Hreflangs";
import ContactForm from "../../components/ContactForm";
import ImgScreenshot from "../../components/ImgScreenshot.js";


const breadCrumbLevels = {
    Accueil: "/",
    "À propos de moi": "/fr/à-propos",
    "SEO Freelance": "/fr/seo-freelance"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
    // replace with pages x-default
    "/en/seo-freelancer"
);


const SeoFreelancer = props => {

    return (
        <Layout location={props.location} alternateLangs={alternateLangs}>
            <React.Fragment>
                <SEO
                    title="Freelance SEO à louer : Profil de M. Kupperschmidt"
                    description="Je suis consultant SEO freelance avec de nombreuses années d'expérience en agence et vous pouvez m'engager pour amener votre site web au sommet des classements Google. Contactez-moi directement."
                    lang="fr"
                    image="matthias-kupperschmidt-presenting-at-founders-house-oct-2019"
                    alternateLangs={alternateLangs}
                    canonical="/fr/seo-freelance"
                />
                <MainContent article>
                    <Img
                        src="seo-freelancer/tag-management-consultant-hero-cropped.jpg"
                        alt="Matthias Kupperschmidt parlant de la difficulté des mots-clés lors d'une rencontre de consultants SEO à Copenhague, Danemark, en octobre 2019"
                    />
                    <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
                    <H as="h1">Engager un consultant SEO freelance</H>
                    <ProfileCard
                        tags={["SEO Content", "Backlinks", "Technical SEO", "Onpage SEO", "HTML/CSS/JavaScript", "React", "Python", "EN, DE, DA"]}
                        profession="Consultant SEO"
                        rate={`à partir de ${monthlySeo}€ par mois`}
                        location="Berlin, Allemagne / à distance"
                        cta="Contactez"
                    />
<p>Je suis un consultant SEO freelance et vous pouvez m'engager pour amener votre site web au sommet des résultats de recherche Google.</p>
<p>J'offre le même service qu'une agence SEO, mais en tant que consultant SEO indépendant. Je m'occupe de la planification, de l'exécution et de l'implémentation du code ou je travaille avec votre agence web.</p>
<p>Auparavant, j'ai travaillé dans diverses agences SEO pour des clients d'entreprise et des entreprises de commerce électronique, faisant du référencement au quotidien.</p>
<p>En 2018, j'ai fait le saut pour commencer à travailler en tant que consultant SEO freelance. En plus d'optimiser les classements des moteurs de recherche de Google, je crée également des configurations de suivi professionnelles avec, par exemple, Google Analytics et Google Tag Manager.</p>
<p>
    Vous pouvez lire mes <a target="_blank" noopener="true" href="https://www.trustpilot.com/review/bluerivermountains.com">avis</a> ou en savoir plus sur ma carrière sur{" "}
    <a target="_blank" noopener="true" href="https://www.linkedin.com/in/matthiaskupperschmidt">
        Linkedin.
    </a>{" "}
    Mon histoire personnelle et d'autres détails sont sur ma <Link to="/fr/à-propos">page à propos</Link>.
</p>
<br />
<br />
<H as="h6" style={{ "textAlign": "center" }}>Avec qui j'ai travaillé</H>
<ImgScreenshot
    src="about/clients_700px.png"
    alt="clients avec lesquels j'ai travaillé"
    className="article-img"
/>
<br />
<br />
<br />

<H as="h2" style={{ "textAlign": "center" }}>Obtenir un devis</H>
<ContactForm showHeadline={false} formName="ga consultant (FR) - formulaire de contact" />

<H as="h2">Services SEO</H>
<p>
    En général, je travaille sur tous les facteurs de classement SEO importants pour optimiser les sites web pour les moteurs de recherche, tout comme
    n'importe quelle entreprise SEO.
</p>
<p>Voici une courte liste de mes services SEO :</p>
<ul>
    <li>Optimisation on-page & off-page</li>
    <li>Stratégies de création de backlinks évolutives</li>
    <li>Création de contenu en plusieurs langues</li>
    <li>SEO JavaScript</li>
    <li>Optimisation de la vitesse de chargement</li>
    <li>Consultation technique SEO</li>
    <li>Structure du site</li>
    <li>Pages mobiles accélérées (AMP)</li>
    <li>pas de SEO local</li>
</ul>
<p>Bien que je me concentre sur le SEO, je suis bien conscient qu'il existe d'autres sources de trafic payant puissantes en plus du trafic de recherche, par exemple Facebook, Twitter ou les annonces Google. C'est pourquoi j'implémente mes techniques SEO en tenant compte d'autres disciplines du marketing internet, afin que nous puissions <b>tirer parti du trafic organique pour le reciblage</b> dans les campagnes de recherche payante et les campagnes sur les réseaux sociaux.</p>
<p>Grâce à mes connaissances en développement web, je peux également vous aider avec des tâches de conception web en HTML, CSS et JavaScript ou des configurations dans Google Tag Manager et Google Analytics.</p>

<H as="h2">Tarif horaire et coûts</H>
<p>Mon tarif pour la consultation SEO freelance est de <b>{hourlyRate} €</b> net par heure. Les coûts totaux d'un projet SEO dépendent de vos objectifs, mais aussi de l'optimisation actuelle de votre site et des efforts de vos concurrents.</p>
<p>Ci-dessous, quelques facteurs influençant le prix de mon travail de consultation SEO :</p>
<ul>
    <li>Nombre total de pages sur le domaine</li>
    <li>Âge et autorité du domaine</li>
    <li>Compétitivité de la niche</li>
    <li>Défis techniques</li>
    <li>Adaptabilité du frontend</li>
    <li>Nombre de langues pour lesquelles nous optimisons</li>
    <li>Combien de nouveau contenu de site web sera nécessaire ?</li>
    <li>Quelle stratégie de création de liens est requise ?</li>
    <li>Y a-t-il d'autres partenaires qui couvrent des tâches pour le contenu ou les liens ?</li>
</ul>
<br />
<p>Le minimum horaire est généralement de <b>15 heures de travail par mois</b> au moins.</p>
<p>Les clients potentiels doivent faire confiance au jugement de l'expert SEO quant à la force de la concurrence, l'optimisation du site requise et les opportunités existantes.</p>
<p>Lisez ci-dessous où j'explique <Link to="/fr/seo-freelance">comment engager le bon freelance SEO</Link>.</p>
<p>Pour déterminer les prix, l'étendue du contrat est définie au début du projet, en fonction des facteurs ci-dessus.</p>
<p>Pour <b>l'optimisation SEO continue</b>, une étendue de projet mensuelle est basée sur les tâches et les jalons. En fonction du tarif horaire, des efforts SEO planifiés et du temps de travail requis, les coûts SEO sont planifiables et plafonnés chaque mois.</p>
<p>Après un accord, je peux commencer mon travail dès que j'ai accès au site web et aux outils connexes.</p>
<p>Je fournis des <b>rapports SEO</b> sous forme de tableau de bord en direct afin que vous ayez une transparence totale sur les classements de mots-clés et le succès global de mon service SEO. Je paie moi-même pour une variété d'outils SEO et les inclus dans le tarif.</p>
<p>Les clients SEO peuvent parfois n'avoir besoin que d'un <b>petit projet ponctuel</b>. Par exemple, une <b>optimisation de la vitesse de chargement</b>, un <b>audit technique SEO, une analyse concurrentielle</b> ou la mise en œuvre de pages de destination importantes en tant que <b>pages AMP</b>.</p>
<p>Ces petits projets sont définis individuellement et ne sont bien sûr pas liés aux facteurs ci-dessus.</p>

<H as="h2">Avantages d'un freelance SEO par rapport à une agence</H>
<p>En règle générale, les freelances conviennent aux clients qui <b>savent ce qu'ils veulent</b>.</p>
<p>C'est généralement une entreprise qui a déjà travaillé avec une agence et qui a une personne avisée en interne. Ils ont moins besoin d'accompagnement et recherchent plutôt une exécution fiable. Ils veulent que le travail soit fait avec un haut degré de certitude sans trop de présentations PowerPoint et de tactiques de vente additionnelle.</p>
<p>Les freelances livrent exactement cela. Ils se concentrent sur l'exécution tout en ayant peu de temps pour la communication.</p>
<p>En résumé, les avantages de travailler avec un freelance sont les suivants :</p>
<ul>
    <li><b>meilleur prix</b></li>
    <li><b>flexibilité</b> car vous pouvez engager en fonction des besoins</li>
    <li>plus d'<b>expérience</b></li>
    <li>profils plus <b>spécialisés</b> disponibles</li>
    <li><b>communication</b> directe avec le spécialiste</li>
</ul>

<H as="h2">Inconvénients</H>
<p>Les inconvénients de travailler avec un freelance sont généralement liés, mais non limités, à l'évolutivité. En fin de compte, c'est une seule personne avec qui vous travaillez et qui n'a que deux mains. Avec une équipe, vous pouvez accomplir plus de travail en moins de temps.</p>
<p>D'autres inconvénients potentiels sont :</p>
<ul>
    <li><b>Limitations de l'évolutivité :</b> Si vous vous attendez à ce que le nombre de tâches augmente, une seule personne peut ne pas suffire pour le travail.</li>
    <li><b>Limitations dues à l'expertise :</b> Une agence disposera d'une plus grande variété de personnes pour des conseils.</li>
    <li><b>Fiabilité :</b> Les maladies ou les perturbations imprévues de la vie peuvent être mieux compensées avec une équipe à portée de main.</li>
    <li><b>Incompatibilité de personnalité :</b> Si vous ne vous entendez pas avec votre freelance, vous pouvez devoir passer à autre chose et perdre du temps. Une agence pourrait vous mettre en contact avec une autre personne.</li>
</ul>

<H as="h2">Comment engager le bon freelance ?</H>
<p>Lors de l'embauche de freelances SEO, il est important de valider l'expérience et les compétences SEO de chaque profil.</p>
<p>Par exemple, vous voudrez vous assurer que la technologie de votre site web correspond aux projets passés du freelance. Si vous gérez un site WordPress, la plupart des freelances seront à l'aise de travailler avec. Mais si vous avez une pile web très personnalisée avec, par exemple, React ou Angular en frontend et Sitecore ou Umbraco en backend, vous voudrez vous assurer que cela ne pose pas de problèmes.</p>
<p>Les spécialistes SEO individuels peuvent ne pas être habitués à implémenter des changements via Git, mais votre site web l'exige. Discutez donc du processus de mise en œuvre des changements sur le site web pour découvrir d'éventuels goulots d'étranglement.</p>
<p>Certaines entreprises construisent leur présence web elles-mêmes avec un développeur interne, tandis que d'autres travaillent avec une agence web. Cela a des implications pour la consultation SEO, car les changements peuvent soit être mis en œuvre directement, soit passer par une agence externe. Ce fait seul impacte le coût et le temps requis pour une campagne SEO car plus de couches de communication sont nécessaires jusqu'à ce qu'un changement soit mis en œuvre.</p>
<p>Donc, les experts SEO indépendants doivent être à l'aise avec la configuration donnée des équipes internes et externes et considérer son impact sur la stratégie SEO globale.</p>
<p>Aussi, le parcours professionnel et les lieux de travail précédents du freelance jouent un rôle pour tirer des conclusions sur l'éthique de travail et la fiabilité. La barrière d'entrée pour les freelances SEO sur Upwork est assez basse et peut donc ne pas fournir le bon candidat (je ne dis pas que ce n'est pas possible). C'est pourquoi j'espère offrir une certaine transparence en partageant avec vous mon{" "}
    <a target="_blank" noopener="true" href="https://www.linkedin.com/in/matthiaskupperschmidt">
        profil Linkedin
    </a>{" "}
    à l'avance.
</p>
<p>Une fois que vous avez trouvé quelqu'un, un simple email ou un formulaire de contact rempli devrait suffire pour entamer une discussion. Un freelance SEO professionnel ne prend cependant pas tous les projets qui tombent sur la table. Et c'est bon pour les clients, donc ne prenez pas un refus personnellement.</p>
<p>Il existe différentes stratégies SEO pour différents créneaux et intentions de mots-clés. Les freelances voudront s'assurer que les exigences du créneau et la technologie du site web correspondent à leur expertise.</p>
<p>Si ce n'est pas le cas, les clients devraient être sceptiques. À moins que vous n'ayez besoin d'une tâche SEO relativement standard, vous voudrez trouver un freelance SEO qui est un expert pour votre cas.</p>
<p>Après que l'étendue du projet et les coûts aient été convenus par écrit, le freelance doit avoir accès au site web. Cela signifie l'accès au frontend, au CMS et aux analyses et potentiellement à un serveur FTP. Dès que l'accès est accordé, l'expert SEO est engagé et le temps de travail commence officiellement.</p>

<H as="h2">Comment suis-je devenu freelance en optimisation pour les moteurs de recherche ?</H>
<p>J'ai construit ma première page d'accueil privée à l'âge de 14 ans pour pouvoir partager ma collection de films avec des amis de l'école. Dans notre village, j'étais aussi l'enfant qui réparait les ordinateurs et faisait de nombreuses réinstallations de systèmes d'exploitation.</p>
<p>
    À 19 ans, j'ai créé Kickerkult.de, une boutique en ligne pour le baby-foot, et j'ai commencé à faire de l'optimisation pour les moteurs de recherche pour ma propre recherche de mots-clés. À l'époque, l'optimisation des mots-clés méta et des balises de titre était presque suffisante.
</p>
<p>Bidouiller les sites web et la technologie dans son ensemble a toujours été amusant pour moi, donc j'ai naturellement choisi de travailler à plein temps dans des agences web et le marketing en ligne par la suite. Les collègues et les projets dans ces agences ont été une bonne influence pour élargir mes compétences et rendre mes flux de travail et processus plus professionnels.</p>
<p>Après avoir eu ma part de jobs en SEO, progresser avec une agence de marketing digital est devenu de plus en plus difficile, donc la consultation SEO en freelance était la prochaine étape logique pour moi.</p>
<p>La plupart des <a href="https://www.statista.com/statistics/946967/freelancers-in-europe-by-sector/" target="_blank">freelances en Europe (39%) travaillent dans le secteur du marketing et de la communication</a>. Je voulais approfondir mon sujet et contrôler les projets sur lesquels je travaille, donc devenir freelance était la prochaine étape pour s'assurer que chaque heure soit consacrée à une tâche significative.</p>
<p>Il se trouve que je ne suis pas totalement seul dans cette pensée. En fait, <a href="https://apo.org.au/sites/default/files/resource-files/2016-05/apo-nid201721.pdf" target="_blank">les freelances ou iPros sont le groupe qui croît le plus rapidement sur le marché du travail de l'UE depuis 2004</a> et <a href="https://news.malt.com/wp-content/uploads/2019/02/FREELANCING-IN-EUROPE-2-1.pdf" target="_blank">ils représentent environ 7% de la main-d'œuvre totale dans l'UE</a>.</p>
<p>Je peux maintenant consacrer plus de temps au contenu, à la recherche de mots-clés et à la création de liens, car j'ai besoin de moins de temps pour les tâches administratives ou la gestion de projet, tandis que mes clients obtiennent plus de travail réel pour leur budget. Travailler à plein temps en freelance me permet donc d'avoir une boucle de rétroaction plus rapide, ce qui est nécessaire pour être compétitif et généralement pas réalisable dans une agence SEO.</p>
<p>Je travaille comme spécialiste SEO freelance depuis 2018 maintenant. Je travaille principalement avec des clients d'entreprise ayant des sites web multinationaux, ainsi que des boutiques de commerce électronique suivant une approche axée sur le marketing de contenu. Je ne fais pas de SEO local.</p>

<H as="h3">Vous cherchez de l'aide en SEO ?</H>
<p>
    Si vous cherchez un expert SEO freelance pour vous aider avec vos classements Google, n'hésitez pas à me contacter. Je reviens généralement avec une évaluation de la situation et un plan incluant un devis.
</p>
<p>
    Vous pouvez également trouver plus d'informations sur ma <Link to="/fr/à-propos">page à propos</Link> et potentiellement quelques
    lectures intéressantes sur mon <Link to="/fr/blog">blog</Link>.
</p>
<H as="h4" style={{ "textAlign": "center" }}>Contactez-moi pour une offre sans engagement.</H>
{/* taking the schema off due to bad implications potentially */}
{/* <Helmet>
    <script type="application/ld+json">{Schema}</script>
</Helmet> */}
</MainContent>
</React.Fragment>

</Layout>
);
};

export default SeoFreelancer;

             